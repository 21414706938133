/* Paralaxx Effect */
.LandingPage {
    width: 100vw;
    height: 100vh;
    overflow-y: scroll;
    overflow-x: hidden;
    /* position: relative; */
    scroll-snap-type: y mandatory;
    -webkit-overflow-scrolling: touch;
    scroll-snap-stop: always;
}
@media screen and (max-width:768px){
    .LandingPage{
        scroll-snap-type: none;
    }
}


.LandingPage::-webkit-scrollbar {
    display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.LandingPage {
    -ms-overflow-style: none;
    /* IE and Edge */
    scrollbar-width: none;
    /* Firefox */
}

.Hero,
.SecondSection,
.MarketPlace{
scroll-snap-align: start;
scroll-snap-stop: always;
height: 100vh;
}
.StartOwnStore{
    scroll-snap-stop: always;
    scroll-snap-align: center;
}
.Footer {
    scroll-snap-stop: always;
    scroll-snap-align: start;
}

/* CSS For Header  */
.HeaderSection {
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: #29BDAB;
    padding: 1rem 2rem;
}

.sellualarSecured {
    color: #29BDAB;
    font-family: Inter;
    font-size: 1.25rem;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}

/* CSS for Home Page */
.HeroSection {
    position: relative;
    top: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: center;

}

.bgImage {
    display: flex;
    /* width: 65.625rem; */
    height: 80vh;
    justify-content: center;
    align-items: center;
    width: 100vw;
    /* background-image:url(../assets/Hero1.png); */
    background-position: center;
    background-repeat: no-repeat;
    background-size: 100% 100%;
    animation: bgChange 9s linear infinite;
    opacity: 0.2;
}
@media (max-width:768px) {
    .bgImage{
        background-size: cover;
    }
}
@keyframes bgChange {
    0% {
        background-image: url(../assets/Hero1.png);
    }

    25% {
        background-image: url(../assets/Hero2.png);
    }

    50% {
        background-image: url(../assets/Hero3.png);
    }

    75% {
        background-image: url(../assets/Hero4.png);
    }

    100% {
        background-image: url(../assets/Hero1.png);
    }
}

/* .bgImage img {
    height: 100%;
    opacity: 0.2;
    transition: 0.5s ease-in-out;
} */

.heroText {
    position: absolute;
    color: #29BDAB;
    text-align: center;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.heroText .MainHero {
    color: #29BDAB;
    font-family: Raleway;
    font-size: 12.5vw;
    font-style: normal;
    font-weight: 800;
    line-height: normal;
    letter-spacing: -0.75rem;
    text-transform: uppercase;
}

@media(max-width: 768px) {
    .heroText .MainHero {
        letter-spacing: -0.24375rem;
        font-size: 4.0625rem;
    }
}

.heroText .subtittle {
    color: #FFF;
    text-align: center;
    font-family: Raleway;
    font-size: 1rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.19rem;
    text-transform: uppercase;
}

.heroText .subtittle .span1 {
    color: #F3EA4E;
}

.heroText .subtittle .span2 {
    color: #EB6E65;
}

.heroButton {
    margin-top: 6rem;
}

/* Button CSS */
.buttonMain {
    background: #EB6E65;
    width: 8rem;
    margin: 0 auto;
    border-radius: 12.5rem;
    padding: 15px 20px;
    color: #000;
    font-family: Inter;
    font-size: 1.25rem;
    font-style: normal;
    font-weight: 700;
    line-height: 1rem;
    margin-top: 40px;
    transition: 0.5s ease-in-out;
}
.buttonMain:hover{
    background:#29BDAB;
    transition: 0.5s ease-in-out;
}

/* Second  Section */
.SecondSection {
    height: 100vh;
}

.Section2 {
    height: 80vh;
    width: 100vw;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    width: fit-content;
    margin: 0 0 0 10vw;
}

.Section2 .shape1 {
    content: ' ';
    width: 2rem;
    height: 2rem;
    background: #F3EA4E;
    border-top-right-radius: 100%;
}

.Section2 .FirstHeading {
    color: #FFF;
    font-family: Raleway;
    font-size: 2.5rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.475rem;
    text-transform: uppercase;
}

.Section2 .SecondHeading {
    color: #29BDAB;
    font-family: Raleway;
    font-size: 6rem;
    font-style: normal;
    font-weight: 800;
    line-height: normal;
    letter-spacing: -0.36rem;
    text-transform: uppercase;
}

.Section2 .ThridHeading {
    color: #F3EA4E;
    font-family: Raleway;
    font-size: 2.5rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.475rem;
    text-transform: uppercase;
}

@media(max-width:768px) {
    .SecondSection {
        display: flex;
        flex-direction: column;
        position: relative;
        height: 100vh;
    }

    .Section2 {
        margin-top: 10vh;
    }

    .Section2 .FirstHeading {
        font-size: 1.25rem;
        margin-top: 15px;
    }

    .Section2 .SecondHeading {
        font-size: 3.25rem;
        letter-spacing: -0.18rem;
    }

    .Section2 .ThirdHeading {
        font-size: 1.25rem;
    }

    /* .SellularVerified{
        position: relative;
    } */
    .sellularVerifiedLogo {
        position: absolute;
        top: 11vw;
        right: 0;
    }
}

.Section2 .ExploreBtn button {
    display: flex;
    align-items: center;
    justify-content: center;
    background: #FFFFFF;
    border: none;
    margin-top: 20px;
    border-radius: 12.5rem;
    padding: 10px 20px;
    color: #000;
    font-family: Inter;
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 700;
    line-height: 1rem;
}

.SellularVerified {
    display: flex;
    justify-content: space-between;
    align-items: center;
    /* position: relative; */
}

.linearDivison {
    content: ' ';
    width: 50vw;
    height: 5px;
    background: linear-gradient(270deg, rgba(41, 189, 171, 0.00) 0%, #29BDAB 50%, #29BDAB 100%);
}

.sellularVerifiedLogo {
    display: flex;
    align-items: center;
    justify-content: center;
}

.sellularVerifiedLogo {
    /* background: url(../assets/circle.svg); */
    background-image: url(../assets/SellularSecured.svg);
    background-position: 2.5rem;
    background-size: 4.6rem;
    background-repeat: no-repeat;
    width: 8rem;
    height: 8rem;
    margin-right: calc(100vw - 95vw);
    display: flex;
    justify-content: center;
    align-items: center;
}

.sellularVerifiedLogo img {
    margin-left: 10px;
    animation: rotate 9s linear infinite;
}

@keyframes rotate {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

/* Market Place */
.MarketPlace {
    width: 100vw;
    height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
}

.MarketPalceParent {
    position: relative;
    /* display: flex; */
    width: 100%;
}

.RightSection {
    position: absolute;
    top: 0;
    right: 0;
    width: 50vw;
}

.RightSection .Overlay {
    background: linear-gradient(90deg, #000 0%, rgba(0, 0, 0, 0.61) 49.55%, rgba(0, 0, 0, 0.00) 97.08%);
    height: 100%;
    width: 100%;
    position: absolute;
    z-index: 1;
    top: 0;
}

.marketPlaceSection {
    color: #FFF;
    width: fit-content;
    margin: 0 5rem 7rem 10vw;
}

.marketPlaceSection .heading div:nth-child(1) {
    color: #FFF;
    font-family: Raleway;
    font-size: 2rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.38rem;
    text-transform: uppercase;
}

.marketPlaceSection .heading div:nth-child(2),
.marketPlaceSection .heading div:nth-child(3),
.marketPlaceSection .heading div:nth-child(4) {
    color: #FFF;
    font-family: Raleway;
    font-size: 6rem;
    font-style: normal;
    font-weight: 800;
    line-height: normal;
    letter-spacing: -0.36rem;
    text-transform: uppercase;
}

.marketPlaceSection .heading div:nth-child(4) span {
    color: #29BDAB;
    position: relative;
}

.marketPlaceSection .heading div:nth-child(4) span::after {
    content: " ";
    width: 45px;
    height: 18px;
    background: #29BDAB;
    position: absolute;
    border-radius: 12.5rem;
    bottom: calc(100% - 90%);
    left: calc(100% - 30%);
    transform: rotate(45deg);
}

.marketPlaceSection .heading div:nth-child(5) {
    margin: 1.685rem 0 0 0;
}

.marketPlaceSection .heading div:nth-child(6) {
    margin: 0 0 1.685rem 0;

}

.marketPlaceSection .heading div:nth-child(5),
.marketPlaceSection .heading div:nth-child(6) {
    color: #FFF;
    font-family: Raleway;
    font-size: 1.2rem;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}

.marketPlaceSection div:nth-child(2) button {
    background: #EB6E65;
    border: none;
    color: #FFF;
    font-family: Inter;
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 700;
    line-height: 1rem;
    display: flex;
    justify-content: space-around;
    align-items: center;
    border-radius: 12.5rem;
    width: 8.375rem;
    height: 2.8125rem;
}

.marketPlaceSection div:nth-child(2) button img {
    width: 1.0625rem;
    height: 1.0625rem;
}

/* Category Scroll */
.ScrollBorder {
    display: flex;
    align-items: center;
}

.ScrollBorder span {
    content: ' ';
    width: 3vw;
    height: 10px;
    background: #ffffff;
    margin: 40px 10px 40px 10px;
}

.ScrollWrapper {
    overflow-x: hidden;
    width: 50vw;
    display: flex;
    align-items: flex-end;
    margin-left: auto;
}

.ScrollContainer {
    /* transform: translateX(-100px); */
    display: flex;

    animation: scrollMove 15s linear infinite;
    /* margin: 0 15px; */
}

.ScrollContainer img {
    margin: 0 5px;
}

@keyframes scrollMove {
    0% {
        transform: translateX(0%);
    }

    100% {
        transform: translateX(-200%);
    }
}

@media(max-width:768px) {
    .MarketPlace {
        height: 100vh;
    }

    .marketPlaceSection {
        margin: 2rem 5rem 2rem 10vw;
    }

    .marketPlaceSection .heading div:nth-child(1) {
        font-size: 1.25rem;
        width: 90vw;
        text-align: left;
    }
    .linearDivison{
        display: none;
    }
    .marketPlaceSection .heading div:nth-child(2),
    .marketPlaceSection .heading div:nth-child(3),
    .marketPlaceSection .heading div:nth-child(4) {
        letter-spacing: -0.18rem;
        font-size: 3.125rem;
    }

    .marketPlaceSection .heading div:nth-child(4) span {
        margin: 0 3px;
    }

    .marketPlaceSection .heading div:nth-child(4) span::after {
        width: 30px;
        height: 8px;
    }

    .marketPlaceSection .heading div:nth-child(5),
    .marketPlaceSection .heading div:nth-child(6) {
        font-size: 0.5rem;
    }

    .marketPlaceSection div:nth-child(2) button {
        width: 5.625rem;
        height: 1.5rem;
        font-size: 0.625rem;
    }

    .MarketPalceParent {
        display: flex;
        flex-direction: column;
        /* height: 100vh; */
    }

    .RightSection {
        position: relative;
        width: 100vw;
        margin-bottom: 1.25rem;
    }

    .ScrollWrapper {
        width: 100vw;
    }

    .ScrollWrapper .ScrollContainer img {
        height: 20vh;
    }

    .ScrollBorder {
        overflow: hidden;
        width: fit-content;
        /* margin: 0 0 0 10vw; */
    }

    .ScrollBorder span {
        width: 30px;
        height: 7px;
        margin: 15px 5px;
    }
}

/* Start Own CSS */
/* .StartMainContainer {
    width: 100vw;
    height: 100vh;
    position: relative;
    display: flex;
    align-items: center;
    gap: 30vw;
} */

/* .StartMainContainer .leftContainer {
    width: 30vw;
    margin: 0 0 0 10vw;
} */
.MainContanier {
    display: flex;
    justify-content: space-between;
    /* align-items: center; */
}

.MainContanier .textcontainer {
    margin: 0 0 0 10vw;
    scroll-snap-type: y mandatory;
}

.MainContanier .imagecontainer {
    /* margin: 0 10vw 0 0; */
    height: 100vh;
    width: 30vw;
    /* position: sticky; */
    top: 0;
    left: 0;
}

.MainContanier .textcontainer .container,
.MainContanier .textcontainer .container,
.MainContanier .textcontainer .container {
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    scroll-snap-align: center;
}

.MainContanier .textcontainer .heading div:nth-child(1),
.MainContanier .textcontainer .heading div:nth-child(2) {
    color: #FFF;
    font-family: Raleway;
    font-size: 6rem;
    font-style: normal;
    font-weight: 800;
    line-height: normal;
    letter-spacing: -0.36rem;
    text-transform: uppercase;
}

.MainContanier .textcontainer .heading div:nth-child(3) {
    font-family: Raleway;
    font-size: 6rem;
    font-style: normal;
    font-weight: 800;
    line-height: normal;
    letter-spacing: -0.36rem;
    text-transform: uppercase;
    transition: 1s ease-in-out;
}

.MainContanier .textcontainer .heading div:nth-child(4),
.MainContanier .textcontainer .heading div:nth-child(5) {
    color: #FFF;
    font-family: Raleway;
    font-size: 1rem;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    margin: 20px 0;
}

.MainContanier .textcontainer div:nth-child(2) button {
    background: #EB6E65;
    border: none;
    border-radius: 12.5rem;
    width: 8.375rem;
    height: 2.8125rem;
    color: #FFF;
    font-family: Inter;
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 700;
    line-height: 1rem;
}

.teal {
    color: #29BDAB !important;
}

.red {
    color: #EB6E65 !important;
}

.yellow {
    color: #F3EA4E !important;
}

.MainContanier .imagecontainer .Img1,
.MainContanier .imagecontainer .Img2,
.MainContanier .imagecontainer .Img3 {
    height: 100vh;
    display: flex;
    align-items: center;
    /* position: absolute; */
    /* opacity: 0; */
}

.visible {
    opacity: 1;
    transition: ease-in-out;
}

@media(max-width:768px) {
    .MainContanier {
        display: flex;
        flex-direction: column;
        height: 100vh;
    }

    .MainContanier .textcontainer {
        display: flex;
        overflow-y: scroll;
        scroll-snap-type: x mandatory;
    }

    .MainContanier .textcontainer .container {
        height: fit-content;
    }

    .MainContanier .textcontainer .container .heading {
        width: 100vw;
        scroll-snap-align: flex-start;
    }

    .MainContanier .textcontainer .heading div:nth-child(1),
    .MainContanier .textcontainer .heading div:nth-child(2),
    .MainContanier .textcontainer .heading div:nth-child(3) {
        font-size: 3.125rem;

    }

    .MainContanier .imagecontainer {
        display: flex;
        height: fit-content;
    }

    .MainContanier .imagecontainer .Img1,
    .MainContanier .imagecontainer .Img2,
    .MainContanier .imagecontainer .Img3 {
        height: fit-content;
    }
}

/* Carousel in Mobile view */
.mobileOwnStore{
    /* margin-left: 10vw; */
    display: flex;
    /* flex-direction: column; */
    align-items: center;
    /* width: 100vw; */
    /* overflow-x: hidden; */
    /* scroll-snap-type: x mandatory; */
    height: 100%;
}
.mobileOwnStore .Container1{
    width: 100vw;
    padding: 2rem 10vw;
    /* overflow: hidden; */
    /* scroll-snap-align: center; */
}
.mobileOwnStore .Container1 .text .heading div:nth-child(1),
.mobileOwnStore .Container1 .text .heading div:nth-child(2),
.mobileOwnStore .Container1 .text .heading div:nth-child(3){
    color: #FFF;
    font-family: Raleway;
    font-size: 3.125rem;
    font-style: normal;
    font-weight: 800;
    line-height: normal;
    letter-spacing: -0.1875rem;
    text-transform: uppercase;
}
.mobileOwnStore .Container1 .text div button{
    background:#EB6E65;
    border: none;
    background: #EB6E65;
    border: none;
    border-radius: 12.5rem;
    width: 6.375rem;
    height: 1.8125rem;
    color: #FFF;
    font-family: Inter;
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 700;
    line-height: 1rem;
}
.mobileOwnStore .Container1 .text{
    overflow: hidden;
}
.mobileOwnStore .Container1 .image{
    margin-top: 40px;
    overflow: hidden;
}
.mobileOwnStore .Container1 .image img{
    width: 80vw;
    height: 30vh;
}
.page1{
    transform: translateX(0%);
    transition: 1s ease-in-out;
}
.page2{
    transform: translateX(-100%);
    transition: 1s ease-in-out;
}
.page3{
    transition: 1s ease-in-out;
    transform: translateX(-200%);
}
/* Footer CSS */
.Footer {
    width: 100vw;
    padding: 50px 0;
}

.Footer .FooterContainer {
    display: flex;
    align-items: center;
    justify-content: space-evenly;
}

.Footer .FooterContainer .FooterSection1 .LogoContainer {
    color: #FFF;
    text-align: center;
    font-family: Roboto;
    font-size: 1.26519rem;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    display: flex;
    align-items: center;
}

.Footer .FooterContainer .FooterSection1 .LogoContainer span {
    margin-left: 20px;
}

.Footer .FooterContainer .FooterSection1 .BBT {
    margin-left: calc(20px + 60px);
}

.Footer .FooterContainer .FooterSection1 .BBT div:nth-child(1) {
    color: #F3EA4E;
    text-align: start;
    font-family: Roboto;
    font-size: 1.25rem;
    font-style: normal;
    font-weight: 900;
    line-height: normal;
    margin-bottom: 20px;
}

.Footer .FooterContainer .FooterSection1 .BBT div:nth-child(2) .BBTItem{
    color: #FFF;
    font-family: Roboto;
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 400;
    line-height: 1.5rem;
    /* text-align: center; */
    margin-bottom: 0;
    display: flex;
    align-items: center;
    /* margin-left: -35px; */
}

.Footer .FooterContainer .FooterSection1 .BBT div:nth-child(2) .BBTItem img {
    /* margin-top: 2px; */
    margin-right: 10px;
    width: 1.5rem;
    height: 1.5rem;
}

.Footer .FooterContainer .seprationLine {
    content: ' ';
    width: 0.1875rem;
    height: 14.18844rem;
    background: linear-gradient(180deg, rgba(41, 189, 171, 0.00) 0%, #29BDAB 50%, rgba(41, 189, 171, 0.00) 100%);
}

.Footer .FooterContainer .FooterSection2 {
    display: flex;
    flex-direction: column;
    align-items: flex-end;

}

.Footer .FooterContainer .FooterSection2 .heading {
    color: #FFF;
    text-align: right;
    font-family: Roboto;
    font-size: 1.125rem;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}

.Footer .FooterContainer .FooterSection2 div:nth-child(2) {
    color: #FFF;
    text-align: right;
    font-family: Roboto;
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 400;
    line-height: 1.25rem;
}

.Footer .FooterContainer .FooterSection2 .SocilaMediaIcon {
    display: flex;
    margin-top: 20px;
    align-items: flex-end;
    justify-content: space-around;
    width: 50%;
    margin: 20px 15px;
}

.Footer .FooterContainer .FooterSection2 .SocilaMediaIcon div {
    width: fit-content;
    height: fit-content;
    display: flex;
    align-items: center;
    margin: 0 5px;
}

.Footer .FooterContainer .FooterSection2 .SocilaMediaIcon div img {
    width: 1.5rem;
    height: 1.5rem;
    flex-shrink: 0;
}

.commingSoon {
    color: #FFF;
    font-family: Raleway;
    font-size: 4rem;
    font-style: normal;
    font-weight: 800;
    line-height: normal;
    letter-spacing: -0.24rem;
    text-transform: uppercase;
    width: 100vw;
    background: #29BDAB;
    text-align: center;
}

@media(max-width:768px) {
    .commingSoon {
        color: #FFF;
        font-family: Raleway;
        font-size: 1.25rem;
        font-style: normal;
        font-weight: 800;
        line-height: normal;
        letter-spacing: -0.075rem;
        text-transform: uppercase;
    }

    .Footer {
        padding: 20px 0;
    }
    .Footer .FooterContainer {
        flex-direction: column;
    }
    /* .Footer .FooterContainer .FooterSection1 .LogoContainer img {
        height: 1rem;
        width: 1rem;
        margin: 0 5px 0 5px;
    } */.Footer .FooterContainer .FooterSection1 .LogoContainer div{
            margin-left: 5px;
    }
    .Footer .FooterContainer .FooterSection1 .BBT div:nth-child(1){
        margin: 0 0 20px 0;
    } 
    .Footer .FooterContainer .FooterSection1 div:nth-child(1) {
        color: #FFF;
        font-family: Roboto;
        /* font-size: 0.375rem; */
        font-style: normal;
        font-weight: 600;
        line-height: 0%;
        margin-left: 1.25rem;
    }
    /* .Footer .FooterContainer .FooterSection2 div:nth-child(2) {
        font-size: 0.375rem;
        line-height: 0.75rem;
    } */

    /* .Footer .FooterContainer .FooterSection2 .SocilaMediaIcon div img {
        width: 0.75rem;
        height: 0.75rem;
    } */

    .Footer .FooterContainer .seprationLine {
        width: 100vw;
        height: 0.2rem;
        margin: 20px 0;
        background: linear-gradient(270deg, rgba(41, 189, 171, 0.00) 0%, #29BDAB 50%, rgba(41, 189, 171, 0.00) 100%);
    }
    .Footer .FooterContainer .FooterSection2 {
        width: 100vw;
        align-items: flex-start;
        margin-left: 1.5rem;
    }
    .Footer .FooterContainer .FooterSection2 div:nth-child(2){
        text-align: left; 
       }
       .Footer .FooterContainer .FooterSection1 .BBT{
           margin-left: 1.25rem;
           width: 100vw;
        }
       .Footer .FooterContainer .FooterSection2 .SocilaMediaIcon{
        margin: 20px 0;
       }
}